import React, { useState } from "react";
import Container from "./container";
import Icon from "./icon";

import * as styles from "../styles/components/home-remaining-days-calculator.module.css";

const DaysCalculator = (props) => {

  const {
    description
  } = props;

  let dayInput = React.createRef();
  let monthInput = React.createRef();
  let yearInput = React.createRef();

  const currentDate = new Date();

  const [result, setResult] = useState('');
  const [showInput, setShowInput] = useState(true);
  const [showResult, setShowResult] = useState(false);

  function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

  function isValidDate(year, month, day) {
    var d = new Date(year, month, day);
    if (d.getFullYear() === Number(year) && d.getMonth() === Number(month) && d.getDate() === Number(day)) {
        return true;
    }
    return false;
  }

  function getDifferenceInDays(date1, date2) {
    const diffInDays = Math.floor((Math.abs(date2-date1))/(1000*60*60*24));
    return diffInDays;
  }

  function getDifferenceInYears(date1, date2) {
    const diffInYears = Math.floor((Math.abs(date2-date1))/(1000*60*60*24*365));
    return diffInYears;
  }

  function submitClick(e) {

    e.preventDefault();

    if ((!isNumber(monthInput.current.value) || !isNumber(dayInput.current.value) || !isNumber(yearInput.current.value)) || !isValidDate(yearInput.current.value, Number(monthInput.current.value)-1, dayInput.current.value)) {
      setResult('Please enter a valid date.');
    }
    else {
      const birthday = new Date(monthInput.current.value+'/'+dayInput.current.value+'/'+yearInput.current.value);
      const year100Date = new Date(monthInput.current.value+'/'+dayInput.current.value+'/'+(Number(yearInput.current.value)+100));
      const resultDays = getDifferenceInDays(currentDate, year100Date);
      const resultYears = getDifferenceInYears(currentDate, birthday);

      if(currentDate.getTime() >= year100Date.getTime()) {
        setResult('Congratulations on living to '+resultYears+' years old!');
      }
      else {
        setResult('You have '+resultDays+' days left to live.');
      }
    }

    setShowInput(false);
    setShowResult(true);
    
  }

  function resetClick() {
    setShowInput(true);
    setShowResult(false);
  }

  return (
    <div className={styles.root}>
      <Container>
        {description && (
          <div className={styles.containerContent} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            <p>{description}</p>
          </div>
        )}
        <div className={styles.calculator} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">

          <form>

            {showInput &&  (
              <div className={styles.dataInput}>
                <span className={styles.heading}>Enter your birthday</span>
                <div className={styles.fields}>
                  <label htmlFor="day">Day:</label>
                  <input type="number" data-date="day" min="1" max="31" placeholder="DD" ref={dayInput} name="day" id="day" />
                  <span className={styles.divider}>/</span>
                  <label htmlFor="month">Day:</label>
                  <input type="number" data-date="month" min="1" max="12" placeholder="MM" ref={monthInput} name="month" id="month" />
                  <span className={styles.divider}>/</span>
                  <label htmlFor="year">Day:</label>
                  <input type="number" data-date="year" min="1871" max="2171" placeholder="YYYY" ref={yearInput} name="year" id="year" />
                </div>
                <button 
                  className={styles.submit} 
                  type="button"
                  onClick={submitClick}
                >
                  Submit
                </button>
              </div>
            )}

            {showResult &&  (
              <div className={styles.result}>
                <p>{result}</p>
                <button 
                  className={styles.reset} 
                  type="reset"
                  onClick={resetClick}
                >
                  Reset<Icon symbol="reset" />
                </button>
              </div>
            )}

          </form>
        </div>
      </Container>
    </div>
  );
}

export default DaysCalculator;

import React from "react";
import { Link } from "gatsby";
import Icon from "./icon";

import * as styles from "../styles/components/link-list.module.css";

function LinkList({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ link, _key }) => {
        return (
          <>
          {link && link.linkUrl && (
            <li key={_key}>
              {link.externalContent ? 
                (
                  <h3 className="h4"><a href={link.linkUrl} target="_blank" rel="noreferrer">{link.text}<Icon symbol="external" /></a></h3>
                ) : (
                  <h3 className="h4"><Link to={`${link.linkUrl}`}>{link.text}</Link></h3> 
                )
              }
            </li>
          )}
          </>
        );
      })}
    </ul>
  );
}

export default LinkList;

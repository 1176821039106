import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import Helmet from 'react-helmet';

import HomeBanner from "../components/home-banner";
import FeaturedBlock from "../components/home-featured-block";
import ContentBlock from "../components/home-content-block";
import DaysCalculator from "../components/home-remaining-days-calculator";
import About from "../components/home-about";
import FooterImage from "../components/footer-image";

import "../styles/templates/home.template.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    home: sanityHome {
      homeBanner {
        text
        image {
          ...SanityImage
          alt
        }
      }
      homeFeaturedBlock {
        _rawHeading
        topSubheading
        bottomSubheading
        description
        image {
          ...SanityImage
          alt
        }
        link {
          text
          linkUrl
          externalContent
        }
      }
      homeContentBlock1 {
        heading
        description
        featuredProjects {
          _key
          project {
            title
            link {
              externalContent
              linkUrl
            }
          }
        }
        link {
          text
          linkUrl
          externalContent
        }
      }
      homeContentBlock2 {
        heading
        description
        featuredLinks {
          _key
          link {
            text
            externalContent
            linkUrl
          }
        }
        link {
          text
          linkUrl
          externalContent
        }
      }
      remainingDaysCalculatorDescription
      homeAbout {
        heading
        description
        link {
          externalContent
          linkUrl
          text
        }
        image {
          ...SanityImage
          alt
        }
      }
      footerImage {
        asset {
          _id
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <Helmet>
        <body className="home" />
      </Helmet>
      <Seo
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />

      {home.homeBanner && (
        <HomeBanner {...home.homeBanner} />
      )}

      {home.homeFeaturedBlock && (
        <FeaturedBlock {...home.homeFeaturedBlock} />
      )}

      {(home.homeContentBlock1 || home.homeContentBlock2) && (
        <ContentBlock block1={home.homeContentBlock1} block2={home.homeContentBlock2} />
      )}

      <DaysCalculator description={home.remainingDaysCalculatorDescription} />

      {home.homeAbout && (
        <About {...home.homeAbout} />
      )}

      {home.footerImage && (
        <FooterImage image={home.footerImage} />
      )}
    </Layout>
  );
};

export default IndexPage;

import React, { useEffect, useContext } from "react";
import { Link } from "gatsby";
import Icon from "./icon";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";
import { Parallax, ParallaxContext } from 'react-scroll-parallax';

import * as styles from "../styles/components/home-featured-block.module.css";

const FeaturedBlock = (props) => {

  const {
    _rawHeading,
    topSubheading,
    bottomSubheading,
    description,
    image,
    link
  } = props;

  const context = useContext(ParallaxContext);

  useEffect(() => {
    if (context) {
      context.update();
    }
  });

  const onImageLoad = () => {
    if (context) {
      context.update();
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
        <Container>
          {topSubheading && (
            <div className={styles.topSubheading}>
              {topSubheading}
            </div>
          )}
          {_rawHeading && (
          <h2>
            <PortableText blocks={_rawHeading} />
          </h2>
          )}
          <div className={styles.containerContent}>
            {bottomSubheading && (
              <div className={styles.bottomSubheading}>
                {bottomSubheading}
              </div>
            )}
            {description && (
              <p>
                {description}
              </p>
            )}
            {link && link.linkUrl && (
              <p>
                {link.externalContent ? 
                  (
                    <a className={styles.more} href={link.linkUrl} target="_blank" rel="noreferrer">{link.text}<Icon symbol="external" /></a>
                  ) : (
                    <Link className={styles.more} to={`${link.linkUrl}`}>{link.text}</Link> 
                  )
                }
              </p>
            )}
          </div>
        </Container>
      </div>
      {image && image.asset && (
        <div className={styles.image} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <Parallax y={["50px", "-100px"]}>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
              onLoad={onImageLoad}
            />
          </Parallax>
        </div>
      )}
    </div>
  );
}

export default FeaturedBlock;

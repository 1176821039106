// extracted by mini-css-extract-plugin
export const root = "home-remaining-days-calculator-module--root--y3Qul";
export const containerContent = "home-remaining-days-calculator-module--containerContent--23ySt";
export const calculator = "home-remaining-days-calculator-module--calculator--1r82x";
export const heading = "home-remaining-days-calculator-module--heading--1MIJP";
export const fields = "home-remaining-days-calculator-module--fields--33jkB";
export const divider = "home-remaining-days-calculator-module--divider--3PhHQ";
export const submit = "home-remaining-days-calculator-module--submit--y7D3I";
export const dataInput = "home-remaining-days-calculator-module--dataInput--GV6AV";
export const fadeInSlide = "home-remaining-days-calculator-module--fadeInSlide--Pxc_Q";
export const result = "home-remaining-days-calculator-module--result--1MG4I";
export const reset = "home-remaining-days-calculator-module--reset--2pwEy";
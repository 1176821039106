import React, { useEffect, useContext } from "react";
import { Link } from "gatsby";
import Icon from "./icon";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { Parallax, ParallaxContext } from 'react-scroll-parallax';

import * as styles from "../styles/components/home-about.module.css";

const About = (props) => {

  const {
    heading,
    description,
    link,
    image
  } = props;

  const context = useContext(ParallaxContext);

  useEffect(() => {
    if (context) {
      context.update();
    }
  });

  const onImageLoad = () => {
    if (context) {
      context.update();
    }
  }

  return (
    <div className={styles.root}>
      <Container>
        {image && image.asset && (
          <div className={styles.image} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            <Parallax y={["50px", "-100px"]}>
              <img
                src={imageUrlFor(buildImageObj(image))
                  .auto("format")
                  .url()}
                alt={image.alt}
                onLoad={onImageLoad}
              />
            </Parallax>
          </div>
        )}
        {(heading || description) && (
          <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            {heading && (
              <h2>
                {heading}
              </h2>
            )}
            {description && (
              <p>
                {description}
              </p>
            )}
            {link && link.linkUrl && (
              <p>
                {link.externalContent ? 
                  (
                    <a className={styles.more} href={link.linkUrl} target="_blank" rel="noreferrer">{link.text}<Icon symbol="external" /></a>
                  ) : (
                    <Link className={styles.more} to={`${link.linkUrl}`}>{link.text}</Link> 
                  )
                }
              </p>
            )}
          </div>
        )}
      </Container>
    </div>
  );
}

export default About;

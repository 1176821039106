import React from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/home-banner.module.css";

const HomeBanner = (props) => {

  const {
    text,
    image
  } = props;

  return (
    <div className={styles.root}>
      {image && image.asset && (
        <div className={styles.image}>
          <img
            src={imageUrlFor(buildImageObj(image))
              .auto("format")
              .url()}
            alt={image.alt}
          />
        </div>
      )}
      {text && (
        <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <Container>
            <h1 className="h2 small">{text}</h1>
          </Container>
        </div>
      )}
    </div>
  );
}

export default HomeBanner;

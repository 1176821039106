import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/footer-image.module.css";

const FooterImage = (props) => {

  const {
    image
  } = props;

  return (
    <div className={styles.root}>
      {image && image.asset && (
        <div className={styles.image}>
          <img
            src={imageUrlFor(buildImageObj(image))
              .auto("format")
              .url()}
            alt=""
          />
        </div>
      )}
    </div>
  );
}

export default FooterImage;

import React from "react";
import { Link } from "gatsby";
import ProjectList from "./project-list";
import LinkList from "./link-list";
import Container from "./container";
import Icon from "./icon";

import * as styles from "../styles/components/home-content-block.module.css";

const ContentBlock = (props) => {

  const {
    block1,
    block2
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.containerContent}>
          <div className={styles.flexGrid}>
            {block1 && (
              <div className={styles.col} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                  {block1.heading && (
                    <h2>
                      {block1.heading}
                    </h2>
                  )}
                  {block1.description && (
                    <p>
                      {block1.description}
                    </p>
                  )}
                  {block1.featuredProjects && <ProjectList items={block1.featuredProjects} />}
                  {block1.link && block1.link.linkUrl && (
                    <p>
                      {block1.link.externalContent ? 
                        (
                          <a className={styles.more} href={block1.link.linkUrl} target="_blank" rel="noreferrer">{block1.link.text}<Icon symbol="external" /></a>
                        ) : (
                          <Link className={styles.more} to={`${block1.link.linkUrl}`}>{block1.link.text}</Link> 
                        )
                      }
                    </p>
                  )}
              </div>
            )}
            {block2 && (
              <div className={styles.col} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                  {block2.heading && (
                    <h2>
                      {block2.heading}
                    </h2>
                  )}
                  {block2.description && (
                    <p>
                      {block2.description}
                    </p>
                  )}
                  {block2.featuredLinks && <LinkList items={block2.featuredLinks} />}
                  {block2.link && block2.link.linkUrl && (
                    <p>
                      {block2.link.externalContent ? 
                        (
                          <a className={styles.more} href={block2.link.linkUrl} target="_blank" rel="noreferrer">{block2.link.text}<Icon symbol="external" /></a>
                        ) : (
                          <Link className={styles.more} to={`${block2.link.linkUrl}`}>{block2.link.text}</Link> 
                        )
                      }
                    </p>
                  )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ContentBlock;
